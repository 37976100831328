import Button from "@repo/ui/buttons/Button";
import Constraints from "@repo/ui/layout/Constraints";
import Masonry from "@repo/ui/layout/Masonry";

import { useAdmin } from "~/state/features/admin";

export default function AccountPage() {
  const [, setAdmin] = useAdmin();

  return (
    <Constraints class="p-3">
      <Masonry minColumnWidth={300}>
        <Button
          variant="primary-hard"
          size="full-width"
          onClick={() => {
            setAdmin.logout();
          }}
        >
          Log out (TODO)
        </Button>
      </Masonry>
    </Constraints>
  );
}
